import { DateUtil } from '@/utils/dateutil';
import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pallet-pooling') {
        return `${config.currEnv}`;
    } else {
        return `ayun-quantity-${config.currEnv}`;
    }
}

export default {
	baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,	

	getMaintenances(filterBy, view, currUserId) {
		const url = `${this.baseUrl}/getMaintenances`;
		return axios.post(url, {
			view: view,
			currUserId: currUserId,
			filterBy: JSON.stringify(filterBy)
		});
	},

	addMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/addMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	},

	updateMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/updateMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	},

	completeMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/completeMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	},

	cancelMaintenance(maintenance, currUserId) {
		let url = `${this.baseUrl}/cancelMaintenance`;
		return axios.post(url, {
			currUserId: currUserId,
			currTimeStamp: DateUtil.getCurrentTimestamp(),
			maintenance: JSON.stringify(maintenance)
		});
	}
}
